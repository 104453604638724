import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'

import lang from 'lang'
import { changeModal } from 'services/actions'
import { isUser } from 'common/utils/determine-user'

import { throttledLoadStaffCharterRevision } from '../service/actions'
import StaffCharterModal from './modal'

@connect(
  (state) => ({
    user: state.root.user,
    charter: state.staffCharter.details.charter.item,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    // Only school users see this button, so no need to pass specific school id. The school of the user will be used.
    loadStaffCharter: () => dispatch(throttledLoadStaffCharterRevision()),
  }),
)
export default class ViewCharterButton extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    charter: PropTypes.object,
    className: PropTypes.string,
    hideWhenEmpty: PropTypes.bool,
    changeModal: PropTypes.func,
    loadStaffCharter: PropTypes.func,
  }

  componentDidMount() {
    this.props.loadStaffCharter()
  }

  get charterEmpty() {
    return (
      _.isEmpty(this.props.charter) ||
      _.isEmpty(this.props.charter.id) ||
      _.isEmpty(this.props.charter.emotion_words)
    )
  }

  clickHandler() {
    return () => {
      this.props.changeModal({
        isOpen: true,
        aria: {
          labelledby: 'staff-charter-modal',
        },
        className: 'staff-charter-modal',
        content: <StaffCharterModal />,
      })
    }
  }

  render() {
    if (!isUser.schoolRole(this.props.user) || (this.props.hideWhenEmpty && this.charterEmpty)) {
      return null
    }

    return (
      <button
        type="button"
        className={cn('button view-staff-charter-button', this.props.className)}
        onClick={this.clickHandler()}
        disabled={this.charterEmpty}
        title={this.charterEmpty ? lang.page.charter.staffCharter.noStaffCharterMessage : ''}>
        {lang.page.charter.viewCharterButton}
      </button>
    )
  }
}
