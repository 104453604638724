import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import { connect } from 'react-redux'

import lang from 'lang'
import { replace } from 'common/utils'
import UserImage from 'common/components/user-image'
import { publicUserService } from 'services/users'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
@connect(
  (state, ownProps) => ({
    profile: _.find(state.publicUserProfiles.items, { id: ownProps.user.id }),
  }),
  (dispatch) => ({
    fetchPublicProfile: (payload) => dispatch(publicUserService.actions.request(payload)),
  }),
)
export default class PersonSelfSignupNotification extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      photo_url: PropTypes.string,
    }).isRequired,
    profile: PropTypes.shape({
      name: PropTypes.string,
      photo_url: PropTypes.string,
    }),
    fetchPublicProfile: PropTypes.func,
  }

  componentDidMount() {
    const {
      user: { id },
      profile,
    } = this.props
    // Fetch user data only once
    if (_.isEmpty(profile)) {
      this.props.fetchPublicProfile({ urlParams: { id } })
    }
  }

  get updatedName() {
    const { user, profile } = this.props
    return (profile && profile.name) || user.name
  }

  get updatedPhoto() {
    const { user, profile } = this.props
    return (profile && profile.photo_url) || user.photo_url
  }

  render() {
    return (
      <div className="grid-x">
        <div className="cell medium-shrink notification-image">
          <UserImage name={this.updatedName} photoUrl={this.updatedPhoto} size="36" />
        </div>
        <div className="cell medium-auto notification-body">
          {Parser(
            replace(lang.notifications.events.person.insert_self_signup, [
              _.startCase(this.updatedName),
            ]),
          )}
        </div>
      </div>
    )
  }
}
