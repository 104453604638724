import React from 'react'
import PropTypes from 'prop-types'

class ScrollHOC extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    if (this.props.onWindowScroll) window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    if (this.props.onWindowScroll) window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    if (this.props.onWindowScroll) this.props.onWindowScroll(event)
  }

  render() {
    return this.props.children
  }
}

ScrollHOC.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onWindowScroll: PropTypes.func,
}

export default ScrollHOC
