import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import Link from 'next/link'

import lang from 'lang'
import { replace } from 'common/utils'
import { urls } from 'common/routes'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class AnnouncementNotification extends React.Component {
  static propTypes = {
    message: PropTypes.shape({
      title: PropTypes.string,
    }),
  }

  render() {
    const { title } = this.props.message

    return (
      <Link href={urls.announcements}>
        <a>
          <div>{Parser(replace(lang.notifications.events.message.post, [title]))}</div>
        </a>
      </Link>
    )
  }
}
