import _ from 'lodash'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { formatDistance } from 'date-fns'

export default function CreateNotificationHoc(Component) {
  return class Notification extends React.Component {
    static propTypes = {
      eventType: PropTypes.string.isRequired,
      payload: PropTypes.object.isRequired,
      createdAt: PropTypes.instanceOf(Date).isRequired,
      seenAt: PropTypes.instanceOf(Date),
    }

    get seen() {
      return !!this.props.seenAt
    }

    get className() {
      return cn('list-item', _.kebabCase(this.props.eventType), {
        seen: this.seen,
        new: !this.seen,
      })
    }

    renderDatetime() {
      const { createdAt } = this.props
      return (
        <small>
          {formatDistance(new Date(createdAt), new Date(), {
            unit: 'minute',
            addSuffix: true,
          })}
        </small>
      )
    }

    render() {
      return (
        <div className={this.className}>
          <div>
            <Component
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...this.props.payload}
            />
            {this.renderDatetime()}
          </div>
          {!this.seen && <span className="dot" />}
        </div>
      )
    }
  }
}
