import React from 'react'

import lang from 'lang'
import ViewCharterButton from 'page-modules/charter/staff-charter/details/view-staff-charter-button'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class StaffCharterUpdatedNotification extends React.Component {
  render() {
    return (
      <div>
        {lang.notifications.events.staff_charter.insert}{' '}
        <ViewCharterButton className="small" hideWhenEmpty />
      </div>
    )
  }
}
