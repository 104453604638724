import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Font, StyleSheet, Text } from '@react-pdf/renderer'

import lang from 'lang'

import { emotionActionsReducer } from './utils'

const debug = false

Font.registerHyphenationCallback((word) => [word])

const styles = StyleSheet.create({
  heading: {
    fontSize: 23,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  actions: {
    paddingTop: 10,
  },
})

export default class WordActionsPdf extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    actions: PropTypes.array.isRequired,
    singleWordMode: PropTypes.bool,
  }

  render() {
    const { word, actions, singleWordMode } = this.props
    const reducedActions = emotionActionsReducer({
      actions,
      elementBase: (action) => (
        <Text debug={debug} style={styles.bold}>
          {action}
        </Text>
      ),
    })

    if (reducedActions.length === 0) {
      return null
    }

    const { inOrderToFeel, weWill } = lang.page.charter
    const renderedActions = _.map(reducedActions, (action, index) => (
      <Text key={index}>{action}</Text>
    ))

    if (singleWordMode) {
      return (
        <Text debug={debug} style={styles.actions} wrap={false}>
          {weWill} {renderedActions}.
        </Text>
      )
    }

    return (
      <Text debug={debug} style={styles.actions} wrap={false}>
        {inOrderToFeel}{' '}
        <Text debug={debug} style={styles.bold}>
          {word.name}
        </Text>
        , {weWill} {renderedActions}.
      </Text>
    )
  }
}
