import React from 'react'
import cn from 'classnames'
import { withRouter } from 'next/router'
import Link from 'next/link'
import PropTypes from 'prop-types'
import _ from 'lodash'

const ActiveLiLink = ({ children, className = '', href, activeOnRoutes = [], router }) => {
  const active = _.some([href, ...activeOnRoutes], (h) => _.startsWith(router.pathname, h))

  return (
    <li className={cn(className, { active })}>
      <Link href={href}>{children}</Link>
    </li>
  )
}

ActiveLiLink.propTypes = {
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  activeOnRoutes: PropTypes.array,
  router: PropTypes.object.isRequired,
}

export default withRouter(ActiveLiLink)
