import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import Link from 'next/link'

import lang from 'lang'
import { urls } from 'common/routes'
import { replace } from 'common/utils'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class DistrictCreatedNotification extends React.Component {
  static propTypes = {
    district: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }

  render() {
    const {
      district: { name, slug },
    } = this.props

    return (
      <Link href={urls.districtDetails(slug)}>
        <a>
          <div>{Parser(replace(lang.notifications.events.district.insert, [name]))}</div>
        </a>
      </Link>
    )
  }
}
