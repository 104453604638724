import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

import lang from 'lang'
import { urls } from 'common/routes'
import { schoolCategoriesService } from 'page-modules/curriculum/service'

import NavDropdown from './nav-dropdown'

const renderCategory = (category) => {
  return (
    <Link href={urls.classroomMaterialsCategory(category.slug)}>
      <a title={category.teaser_text}>
        <div className="card-section card-icon">
          <img src={category.image_url} alt={category.name} />
        </div>
        <div className="card-title">{category.name}</div>
      </a>
    </Link>
  )
}

@connect(
  (state) => ({ categories: state.classroomMaterials.categories.items }),
  (dispatch) => ({
    loadCategories: () => dispatch(schoolCategoriesService.actions.request()),
  }),
)
export default class CurriculumNav extends React.Component {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    ),
    loadCategories: PropTypes.func,
  }

  componentDidMount() {
    this.props.loadCategories()
  }

  render() {
    const { categories } = this.props
    return (
      <NavDropdown
        name={lang.curriculum}
        overviewRoute={urls.curriculum}
        className="curriculum-nav-button">
        {_.map(categories, (category) => {
          return (
            <div className="cell" key={category.id}>
              <div className="card curriculum">
                <div className="card-section">{renderCategory(category)}</div>
              </div>
            </div>
          )
        })}
      </NavDropdown>
    )
  }
}
