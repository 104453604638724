import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import lang from 'lang'
import WordActions from 'page-modules/charter/word-actions'

@connect((state) => ({
  school: state.school.details.item,
  charter: state.staffCharter.details.charter.item,
  selectedEmotionWordsIds: state.staffCharter.details.filters.emotionWordsIds,
}))
export default class StaffCharterDetailsContent extends React.Component {
  static propTypes = {
    school: PropTypes.object,
    charter: PropTypes.object,
    hideActions: PropTypes.bool,
    selectedEmotionWordsIds: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    hideActions: false,
  }

  get filteredWords() {
    const {
      selectedEmotionWordsIds,
      charter: { emotion_words: emotionWords },
    } = this.props

    // When there are no emotion words selected assume that there haven't been an interaction with the filter. In the case of this default state => return all emotion words.
    // This allows for displaying all the words deselected and clicking on a word to filter only by that word.
    if (_.isEmpty(selectedEmotionWordsIds)) {
      return emotionWords
    }

    return _.filter(emotionWords, (word) => _.includes(selectedEmotionWordsIds, word.id))
  }

  get sortedWords() {
    return _.orderBy(this.filteredWords, ['name'], ['asc'])
  }

  get singleWordMode() {
    return this.filteredWords && this.filteredWords.length === 1
  }

  renderHeader() {
    const { school } = this.props
    if (!school) {
      return <h3>{lang.page.charter.staffCharter.title}</h3>
    }
    if (school.logo_url) {
      return (
        <div className="charter-logo text-center">
          <img src={school.logo_url} alt={school.name} />
        </div>
      )
    }
    return <h3>{school.name}</h3>
  }

  renderActions() {
    if (this.props.hideActions) {
      return
    }

    return (
      <div className="want-to-feel-actions">
        {_.map(this.sortedWords, (word) => {
          return (
            <WordActions
              key={word.id}
              word={word}
              actions={word.actions}
              singleWordMode={this.singleWordMode}
            />
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <>
        <div className="charter-header">{this.renderHeader()}</div>
        <div className="charter-body">
          <div className="charter-subheader">
            {this.singleWordMode ? lang.page.charter.inOrderToFeel : lang.page.charter.wantToFeel}
          </div>
          <div className="want-to-feel-words">
            {_.map(this.sortedWords, (word, index) => {
              return (
                <span key={word.id}>
                  {word.name}
                  {this.filteredWords.length - 1 === index ? '' : ', '}{' '}
                </span>
              )
            })}
          </div>
          {this.renderActions()}
        </div>
        <div className="charter-footer">{lang.page.charter.poweredBy}</div>
      </>
    )
  }
}
