import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'
import Link from 'next/link'
import { withRouter } from 'next/router'

import lang from 'lang'
import { closeNavDropdown, openNavDropdown } from 'services/nav-dropdown/actions'

@connect(
  (state) => ({
    openedDropdown: state.navDropdown.openedDropdown,
  }),
  (dispatch) => ({
    openNavDropdown: (name) => dispatch(openNavDropdown(name)),
    closeNavDropdown: () => dispatch(closeNavDropdown()),
  }),
)
@withRouter
export default class NavDropdown extends React.Component {
  static propTypes = {
    openedDropdown: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    overviewRoute: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.element),
    openNavDropdown: PropTypes.func,
    closeNavDropdown: PropTypes.func,
    router: PropTypes.object,
  }

  componentDidMount() {
    // Close nav menu when route navigation is complete
    this.props.router.events.on('routeChangeComplete', this.routeChangeHandler())
  }

  componentWillUnmount() {
    this.props.router.events.off('routeChangeComplete', this.routeChangeHandler())
  }

  get opened() {
    return this.props.name === this.props.openedDropdown
  }

  routeChangeHandler() {
    return () => this.props.closeNavDropdown()
  }

  toggleDropdownHandler() {
    return (e) => {
      e.preventDefault()
      e.stopPropagation() // so the ContentWrap event handler is not triggered.
      // We are handling the nav dropdown event here.
      // If propagation is not stopped, the event will be handled by both place, which will be semanticaly wrong.
      // For example the wrong behavior is when clicking on one NavDropdown while another is already shown and all are hidden.
      // When stopping the propagation this is solved.
      // The clicked dropdown's content is shown correctly.
      if (this.opened) {
        this.props.closeNavDropdown()
      } else {
        this.props.openNavDropdown(this.props.name)
      }
    }
  }

  renderDropdown() {
    if (!this.opened) {
      return
    }

    return (
      <div className="nav-items dropdown-pane bottom is-open">
        <div className="grid-container">
          <div className="title">
            <Link href={this.props.overviewRoute}>
              <a>
                <h3>{lang.overview}</h3>
              </a>
            </Link>
          </div>
          <div className="grid-x grid-padding-x medium-up-4 large-up-6 align-center">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <li
        className={cn(`mega-dropdown`, {
          [this.props.className]: this.props.className,
          opened: this.opened,
        })}>
        <a
          href="#"
          aria-controls={this.props.name}
          aria-expanded={this.opened}
          onClick={this.toggleDropdownHandler()}>
          {this.props.name}
        </a>
        <div role="presentation" onClick={(e) => e.stopPropagation()}>
          {this.renderDropdown()}
        </div>
      </li>
    )
  }
}
