import { type } from 'common/services/socket-communication/event-map'

import PersonSelfSignupNotification from './components/person-self-signup-notification'
import SchoolCreatedNotification from './components/school-created-notification'
import DistrictCreatedNotification from './components/district-created-notification'
import StaffCharterUpdatedNotification from './components/staff-charter-updated'
import StaffCharterSurveyUpdatedNotification from './components/staff-charter-survey-updated'
import MoodGroupCreatedNotification from './components/mood-group-created'
import ResourceCreatedNotification from './components/resource-created-notification'
import CourseCreatedNotification from './components/course-created-notification'
import AnnouncementNotification from './components/announcement-notification'

const notifications = {
  [type('person', 'insert_self_signup')]: PersonSelfSignupNotification,
  [type('school', 'insert')]: SchoolCreatedNotification,
  [type('district', 'insert')]: DistrictCreatedNotification,
  [type('staff_charter', 'insert')]: StaffCharterUpdatedNotification,
  [type('staff_charter_survey', 'insert')]: StaffCharterSurveyUpdatedNotification,
  [type('mood_meter_group', 'insert')]: MoodGroupCreatedNotification,
  [type('resource', 'insert')]: ResourceCreatedNotification,
  [type('course', 'insert')]: CourseCreatedNotification,
  [type('message', 'post')]: AnnouncementNotification,
}

export default notifications
