import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

import lang from 'lang'
import WordActionsPdf from 'page-modules/charter/word-actions-pdf'

const debug = false

// Create styles
const styles = StyleSheet.create({
  page: {
    color: '#1e2749',
    textAlign: 'center',
    paddingBottom: '12vh',
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100vh',
    width: '100vw',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'stretch',
  },
  section: {
    paddingTop: 20,
  },
  logo: {
    maxHeight: 200,
    maxWidth: 200,
    marginHorizontal: 100,
    marginTop: 100,
    paddingBottom: 30,
  },
  schoolNameHeading: {
    fontSize: 22,
    marginTop: 100,
    paddingTop: 20,
  },
  words: {
    paddingHorizontal: 80,
    fontSize: 30,
    fontWeight: 'bold',
  },
  largeWords: {
    fontSize: 45,
  },
  footer: {
    fontSize: 10,
    width: '100vw',
    textAlign: 'center',
    position: 'absolute',
    bottom: 32,
  },
})

export default class StaffCharterPdf extends React.Component {
  static propTypes = {
    school: PropTypes.object,
    charter: PropTypes.shape({
      emotion_words: PropTypes.arrayOf(
        PropTypes.shape({
          word: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
          actions: PropTypes.array,
        }),
      ),
    }),
    selectedEmotionWordsIds: PropTypes.arrayOf(PropTypes.string),
  }

  get filteredWords() {
    const {
      selectedEmotionWordsIds,
      charter: { emotion_words: emotionWords },
    } = this.props

    // When there are no emotion words selected assume that there haven't been an interaction with the filter. In the case of this default state => return all emotion words.
    // This allows for displaying all the words deselected and clicking on a word to filter only by that word.
    if (_.isEmpty(selectedEmotionWordsIds)) {
      return emotionWords
    }

    return _.filter(emotionWords, (word) => _.includes(selectedEmotionWordsIds, word.id))
  }

  get sortedWords() {
    return _.orderBy(this.filteredWords, ['name'], ['asc'])
  }

  get singleWordMode() {
    return this.filteredWords.length === 1
  }

  renderFixedElements() {
    const { school } = this.props
    return (
      <>
        <Image
          fixed
          debug={debug}
          src="/static/staff-charter/charter-scroll-pdf.png"
          style={styles.pageBackground}
        />
        {school.logo_url ? (
          <View fixed debug={debug} style={styles.logoSection}>
            <Image src={school.logo_url} style={styles.logo} />
          </View>
        ) : (
          <Text fixed debug={debug} style={styles.schoolNameHeading}>
            {school.name}
          </Text>
        )}
      </>
    )
  }

  renderHeader() {
    return (
      <View debug={debug} style={styles.section}>
        {this.singleWordMode ? (
          <Text debug={debug}>{lang.page.charter.inOrderToFeel}</Text>
        ) : (
          <Text debug={debug}>{lang.page.charter.wantToFeel}</Text>
        )}
      </View>
    )
  }

  renderWords() {
    return (
      <View debug={debug} style={styles.section}>
        <Text
          debug={debug}
          style={[styles.words, ...(this.singleWordMode ? [styles.largeWords] : [])]}>
          {_.join(_.map(this.sortedWords, 'name'), ', ')}
        </Text>
      </View>
    )
  }

  renderActions() {
    return (
      <View debug={debug} style={styles.section}>
        {_.map(this.sortedWords, (word) => {
          return (
            <WordActionsPdf
              key={word.id}
              word={word}
              actions={word.actions}
              singleWordMode={this.singleWordMode}
            />
          )
        })}
      </View>
    )
  }

  render() {
    return (
      <Document>
        <Page size="A4" debug={debug} style={styles.page}>
          {this.renderFixedElements()}
          {this.renderHeader()}
          {this.renderWords()}
          {this.renderActions()}
          <View fixed debug={debug} style={styles.footer}>
            <Text debug={debug}>{lang.page.charter.poweredBy}</Text>
          </View>
        </Page>
      </Document>
    )
  }
}
