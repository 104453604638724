import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'

import lang from 'lang'
import { replace } from 'common/utils'

import { emotionActionsReducer } from './utils'

export default class WordActions extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    actions: PropTypes.array.isRequired,
    singleWordMode: PropTypes.bool,
  }

  render() {
    const { word, actions, singleWordMode } = this.props
    const reducedActions = emotionActionsReducer({
      actions: _.filter(actions),
      elementBase: (action) => `<strong>${action}</strong>`,
    })

    if (reducedActions.length === 0) {
      return null
    }

    if (singleWordMode) {
      return (
        <div className="action-group">
          {lang.page.charter.weWill} {Parser(_.join(reducedActions, ''))}
        </div>
      )
    }

    return (
      <div className="action-group">
        {Parser(
          replace(lang.page.charter.inOrderToFeelThisEmotion, [
            `<strong>${word.name}</strong>`,
            _.join(reducedActions, ''),
          ]),
        )}
      </div>
    )
  }
}
