import _ from 'lodash'
import React from 'react'
import Link from 'next/link'

import lang from 'lang'
import { urls } from 'common/routes'

import NavDropdown from './nav-dropdown'

const menuItems = [
  {
    href: urls.charters(),
    imgSrc: 'https://s3.amazonaws.com/ruleronlineimgs/logos/ruler-charter-icon.svg',
    imgAlt: 'Charter icon',
    title: lang.theCharter,
  },
  {
    href: urls.moodMeter(),
    imgSrc: 'https://s3.amazonaws.com/ruleronlineimgs/logos/ruler-mood-meter-icon.svg',
    imgAlt: 'Mood meter icon',
    title: lang.theMoodMeter,
  },
  {
    href: urls.collectionDetails('the-meta-moment'),
    imgSrc: 'https://s3.amazonaws.com/ruleronlineimgs/logos/ruler-meta-moment-icon.svg',
    imgAlt: 'Meta-moment icon',
    title: lang.theMetaMoment,
  },
  {
    href: urls.collectionDetails('the-blueprint'),
    imgSrc: 'https://s3.amazonaws.com/ruleronlineimgs/logos/ruler-blueprint-icon.svg',
    imgAlt: 'Blueprint icon',
    title: lang.theBlueprint,
  },
  {
    href: urls.rulerize,
    imgSrc: 'https://s3.amazonaws.com/ruleronlineimgs/logos/ruler-rulerize-icon.svg',
    imgAlt: 'Rulerize icon',
    title: lang.rulerize,
  },
  {
    href: urls.journal,
    imgSrc: 'https://s3.amazonaws.com/ruleronlineimgs/logos/ruler-journal-icon.svg',
    imgAlt: 'Journal icon',
    title: lang.journal,
  },
]

export default class ToolsNav extends React.Component {
  render() {
    return (
      <NavDropdown name="Tools" overviewRoute={urls.tools} className="tools-nav-button">
        {_.map(menuItems, (menu, index) => (
          <div key={index} className="cell">
            <div className="card tools">
              <Link href={menu.href}>
                <a>
                  <div className="card-section card-icon">
                    <img src={menu.imgSrc} alt={menu.imgAlt} />
                  </div>
                  <div className="card-section">
                    <h2 className="card-title">{menu.title}</h2>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        ))}
      </NavDropdown>
    )
  }
}
