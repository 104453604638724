import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'

import lang from 'lang'

import { changeEmotionWordsFilters } from '../service/actions'

@connect(
  (state) => ({
    selectedEmotionWordsIds: state.staffCharter.details.filters.emotionWordsIds,
  }),
  (dispatch) => ({
    changeEmotionWordsFilters: (emotionWordsIds) =>
      dispatch(changeEmotionWordsFilters(emotionWordsIds)),
  }),
)
export default class EmotionWordsFilter extends React.Component {
  static propTypes = {
    selectedEmotionWordsIds: PropTypes.arrayOf(PropTypes.string),
    emotionWords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    changeEmotionWordsFilters: PropTypes.func,
  }

  get sortedWords() {
    return _.orderBy(this.props.emotionWords, ['name'], ['asc'])
  }

  toggleEmotionWord(id) {
    const { selectedEmotionWordsIds } = this.props
    // Implements the toggle logic
    const newSelectedEmotionWordsIds = _.xor([id], selectedEmotionWordsIds)
    this.props.changeEmotionWordsFilters(newSelectedEmotionWordsIds)
  }

  clickHandler(wordId) {
    return () => {
      this.toggleEmotionWord(wordId)
    }
  }

  keyPressHandler(wordId) {
    return (e) => {
      if (e.key === 'Enter') {
        this.toggleEmotionWord(wordId)
      }
    }
  }

  render() {
    const { selectedEmotionWordsIds } = this.props

    return (
      <div className="emotion-words-filter">
        {_.map(this.sortedWords, (word) => {
          const selected = _.includes(selectedEmotionWordsIds, word.id)
          const disabled = selected && selectedEmotionWordsIds.length === 1

          return (
            <div
              key={word.id}
              className={cn('emotion-word', {
                selected,
                disabled,
              })}
              onClick={disabled ? _.noop : this.clickHandler(word.id)}
              onKeyPress={disabled ? _.noop : this.keyPressHandler(word.id)}
              role="checkbox"
              aria-checked={selected}
              tabIndex={0}
              title={disabled ? lang.page.charter.staffCharter.lastSelectedWordTooltip : ''}>
              {word.name}
            </div>
          )
        })}
      </div>
    )
  }
}
