import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import { addSeconds } from 'date-fns'
import Link from 'next/link'

import lang from 'lang'
import { urls } from 'common/routes'
import { replace, timeDuration } from 'common/utils'
import GroupTimer from 'page-modules/mood-meter/group-timer'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class MoodGroupCreatedNotification extends React.Component {
  static propTypes = {
    mood_meter_group: PropTypes.shape({
      name: PropTypes.string,
      shared_in: PropTypes.string,
      short_url: PropTypes.string,
      closed: PropTypes.bool,
      duration: PropTypes.string,
      audience_type_id: PropTypes.string,
      start_time: PropTypes.string,
    }).isRequired,
  }

  get startTimeDate() {
    const { start_time } = this.props.mood_meter_group
    return new Date(start_time)
  }

  get endTimeDate() {
    const { duration } = this.props.mood_meter_group
    const seconds = timeDuration(duration).asSeconds()
    return addSeconds(this.startTimeDate, seconds)
  }

  renderViewResults() {
    const { short_url } = this.props.mood_meter_group
    return (
      <Link href={urls.moodMeterGroupDetails(short_url)}>
        <a className="button small">{lang.notifications.events.mood_meter_group.viewResults}</a>
      </Link>
    )
  }

  renderPlotYourself() {
    const { short_url } = this.props.mood_meter_group
    return (
      <Link href={urls.moodMeterPlotInGroup(short_url)}>
        <a className="button small">{this.renderGroupDuration()}</a>
      </Link>
    )
  }

  renderGroupClosed() {
    const { name } = this.props.mood_meter_group
    return (
      <div>
        {replace(lang.notifications.events.mood_meter_group.closed, [name])}
        {this.renderViewResults()}
      </div>
    )
  }

  renderGroupName() {
    const { name } = this.props.mood_meter_group
    return Parser(replace(lang.notifications.events.mood_meter_group.insert, [name]))
  }

  renderGroupDuration() {
    const { duration, closed } = this.props.mood_meter_group
    return (
      <GroupTimer
        duration={duration}
        closed={closed}
        startTimeDate={this.startTimeDate}
        endTimeDate={this.endTimeDate}
      />
    )
  }

  render() {
    const { closed } = this.props.mood_meter_group
    if (closed) {
      return this.renderGroupClosed()
    }

    return (
      <div>
        {this.renderGroupName()}
        <br />
        {this.renderViewResults()} {this.renderPlotYourself()}
      </div>
    )
  }
}
