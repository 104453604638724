import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import lang from 'lang'
import { tabs, urls } from 'common/routes'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class StaffCharterSurveyUpdatedNotification extends React.Component {
  static propTypes = {
    staff_charter_survey: PropTypes.shape({
      closed: PropTypes.bool,
      duration: PropTypes.string,
    }),
  }

  render() {
    const { closed, duration } = this.props.staff_charter_survey
    if (closed) {
      return <div>{lang.notifications.events.staff_charter_survey.closed}</div>
    }

    return (
      <div>
        {lang.notifications.events.staff_charter_survey.insert}{' '}
        {lang.notifications.events.staff_charter_survey.duration} {duration}.
        <Link href={urls.charters(tabs.charter.submitSurvey)}>
          <a className="button small">{lang.page.charter.staffCharter.survey.takeSurveyButton}</a>
        </Link>
      </div>
    )
  }
}
