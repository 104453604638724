import _ from 'lodash'

export const emotionActionsReducer = ({ actions, elementBase }) => {
  const reducedActions = _.reduce(
    _.orderBy(actions, 'name'),
    (result, action, index) => {
      const uiElement = elementBase(action)
      if (index === 0) {
        result.push(uiElement)
      } else if (index === actions.length - 1) {
        result.push(' and ', uiElement)
      } else {
        result.push(', ', uiElement)
      }
      return result
    },
    [],
  )
  return reducedActions
}
