import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ExternalHtml from 'common/services/html-content/external-html'
import { requestHtmlContent } from 'common/services/html-content/actions'

const BANNER_CONTENT_KEY = 'global/top-info-banner'

@connect(
  (state) => ({
    htmlContentStore: state.htmlContent,
  }),
  (dispatch) => ({
    requestHtmlContent: (payload) => dispatch(requestHtmlContent(payload)),
  }),
)
export default class InfoBanner extends React.Component {
  static propTypes = {
    htmlContentStore: PropTypes.object,
    requestHtmlContent: PropTypes.func,
  }

  componentDidMount() {
    this.props.requestHtmlContent({ keys: [BANNER_CONTENT_KEY] })
  }

  render() {
    if (
      !this.props.htmlContentStore[BANNER_CONTENT_KEY] ||
      this.props.htmlContentStore[BANNER_CONTENT_KEY] === ''
    ) {
      return null
    }

    return (
      <div className="top-info-banner">
        <ExternalHtml slug={BANNER_CONTENT_KEY} />
      </div>
    )
  }
}
