import _ from 'lodash'

/**
 * Docs at https://www.filestack.com/docs/api/processing/
 */

const cname = 'https://cdn.filestackcontent.com'

// Handle settings ex. expiry:3600
const processSettings = (settings) =>
  _.map(settings, (value, setting) => {
    const urlValue = _.isArray(value) ? JSON.stringify(value) : value
    return `${setting}:${encodeURIComponent(urlValue)}`
  })

// Handle pairs ex. shadow=opacity:60,vector:[4,4]
const processParts = (params) =>
  _.map(params, (settings, key) => {
    // Handle bool flags
    if (_.isBoolean(settings)) {
      return `${key}`
    }
    return `${key}=${_.join(processSettings(settings), ',')}`
  })

const filestackUrl = ({ url, ...params }) => {
  // Do not alter simple urls
  if (_.isEmpty(params)) {
    return url
  }
  // Handle url enhancements
  const urlParams = _.join(processParts(params), '/')
  return _.replace(url, cname, `${cname}/${urlParams}`)
}

const thumbnailUrl = (url) => {
  return filestackUrl({
    url,
    resize: { height: 400, width: 400 },
    cache: { expiry: 3600 },
    no_metadata: true,
  })
}
const previewImageUrl = (url) => {
  return filestackUrl({
    url,
    resize: { height: 1200, width: 1200 },
    cache: { expiry: 3600 },
    no_metadata: true,
  })
}

export { thumbnailUrl, filestackUrl, previewImageUrl }
export default filestackUrl
