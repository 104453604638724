import React from 'react'

import SchoolLogo from 'components/school-logo'
import lang from 'lang'

export default class StaffCharterPlaceholder extends React.Component {
  render() {
    return (
      <div className="charter-container">
        <div className="charter-template">
          <div className="charter-header">
            <div className="charter-logo text-center">
              <SchoolLogo />
            </div>
          </div>
          <div className="charter-body">
            {lang.page.charter.staffCharter.placeholderForEmptyContent}
          </div>
          <div className="charter-footer">{lang.page.charter.poweredBy}</div>
        </div>
      </div>
    )
  }
}
