import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import ImageZoom from 'react-medium-image-zoom'

import { thumbnailUrl } from 'common/utils/media-link'

const getUserInitials = (name) => {
  name = name.split(' ')
  return name.length > 1
    ? _.join(
        _.map([_.first(name), _.last(name)], (n) => n.charAt(0)),
        '',
      ).toUpperCase()
    : _.first(name).charAt(0).toUpperCase()
}

export default class UserImage extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    size: PropTypes.string,
    fontSize: PropTypes.string,
    enableZoom: PropTypes.bool,
  }

  state = { isEdge: false }

  componentDidMount() {
    this.setState({
      isEdge: document.documentMode || /Edge/.test(window.navigator.userAgent),
    })
  }

  renderProfilePicture() {
    const { name, photoUrl, size, enableZoom } = this.props
    const thumbnailPhotoUrl = thumbnailUrl(photoUrl)

    if (enableZoom) {
      return (
        <ImageZoom
          image={{
            src: thumbnailPhotoUrl,
            alt: name,
            className: `avatar-collapsed`,
          }}
          zoomImage={{
            src: photoUrl,
            alt: name,
            className: `avatar-expanded`,
          }}
        />
      )
    }

    return (
      <img
        alt={name}
        src={thumbnailPhotoUrl}
        srcSet={thumbnailPhotoUrl}
        height={size}
        width={size}
      />
    )
  }

  render() {
    const { name, photoUrl, size, fontSize } = this.props
    const { isEdge } = this.state
    return (
      <div className="user-avatar">
        {photoUrl ? (
          this.renderProfilePicture()
        ) : (
          <svg height={size} width={size} style={{ background: '#036cb6' }}>
            <text
              x="50%"
              y="50%"
              dy={isEdge ? '22%' : '7%'}
              dominantBaseline="middle"
              fill="#ffffff"
              fontSize={fontSize || Math.floor(size / 3)}
              fontFamily="Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif"
              fontWeight="400"
              textAnchor="middle">
              {getUserInitials(name)}
            </text>
          </svg>
        )}
      </div>
    )
  }
}
