import React from 'react'
import lang from 'lang'
import { urls } from 'common/routes'

import ActiveLiLink from '../active-li-link'
import CurriculumNav from './curriculum'
import ToolsNav from './tools'

export default class MainNav extends React.Component {
  render() {
    return (
      <ul id="menu-main-navigation" className="menu align-center">
        <ActiveLiLink
          className="courses menu-item"
          href={urls.courses}
          activeOnRoutes={['/module', '/assessment']}>
          <a>
            <span>{lang.courses}</span>
          </a>
        </ActiveLiLink>
        <ActiveLiLink className="resource-library menu-item" href={urls.resources()}>
          <a>
            <span>{lang.resources}</span>
          </a>
        </ActiveLiLink>
        <CurriculumNav />
        <ToolsNav />
        <li className="coaching menu-item">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rulerapproach.org/ruler-coaching/">
            <span>{lang.coaching}</span>
          </a>
        </li>
      </ul>
    )
  }
}
