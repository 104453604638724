import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { logOut } from 'services/actions'
import { isUser } from 'common/utils'
import { urls } from 'common/routes'

import ActiveLiLink from '../active-li-link'

const MenuItemsForSmallScreen = () => {
  return (
    <>
      <li className="show-for-small-only">
        <br />
      </li>
      <ActiveLiLink
        className="show-for-small-only"
        href={urls.courses}
        activeOnRoutes={['/module', '/assessment']}>
        <a>{lang.courses}</a>
      </ActiveLiLink>
      <ActiveLiLink className="show-for-small-only" href={urls.resources()}>
        <a>{lang.resources}</a>
      </ActiveLiLink>
      <ActiveLiLink className="show-for-small-only" href={urls.curriculum}>
        <a>{lang.curriculum}</a>
      </ActiveLiLink>
      <ActiveLiLink className="show-for-small-only" href={urls.tools}>
        <a>{lang.tools}</a>
      </ActiveLiLink>
      <li className="show-for-small-only">
        <br />
      </li>
    </>
  )
}

@connect(
  (state) => ({
    user: state.root.user,
    district: state.district.details.item,
    school: state.school.details.item,
  }),
  (dispatch) => ({
    logOut: () => dispatch(logOut()),
  }),
)
export default class UserNav extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    school: PropTypes.object,
    district: PropTypes.object,
    logOut: PropTypes.func.isRequired,
  }

  get linksByUserRole() {
    if (isUser.rulerAnyAdmin(this.props.user)) {
      const links = [
        {
          link: urls.districts,
          label: lang.districtProfiles,
        },
        {
          link: urls.schools,
          label: lang.schoolProfiles,
        },
        {
          link: urls.ycei,
          label: lang.dashboard.ycei.menuItem,
        },
        {
          link: urls.statistics(),
          label: lang.statistics,
        },
      ]
      if (isUser.rulerAdmin(this.props.user)) {
        links.push({
          link: urls.administration(),
          label: lang.page.administration.title,
        })
      }
      return links
    }
    if (
      isUser.districtAdmin(this.props.user) ||
      isUser.districtImplementationTeam(this.props.user)
    ) {
      return [
        {
          link: urls.districtDetails(this.props.district.slug),
          label: lang.districtProfile,
        },
        {
          link: urls.schools,
          label: lang.schoolProfiles,
        },
      ]
    }
    if (isUser.schoolRole(this.props.user)) {
      return [
        {
          link: urls.schoolDetails(this.props.school.slug),
          label: lang.schoolProfile,
        },
        {
          link: urls.roadmap,
          label: lang.dashboard.nudges.navMenuItem,
        },
      ]
    }
    return []
  }

  logoutHandler() {
    return (e) => {
      e.preventDefault()
      this.props.logOut()
    }
  }

  render() {
    return (
      <>
        <ul id="menu-user-menu" className="user-nav-links vertical menu">
          <ActiveLiLink href={urls.settings()}>
            <a>{lang.settings}</a>
          </ActiveLiLink>
          <MenuItemsForSmallScreen />
          {this.linksByUserRole.map((menu) => (
            <ActiveLiLink href={menu.link} key={menu.link}>
              <a>{menu.label}</a>
            </ActiveLiLink>
          ))}
          <ActiveLiLink href={urls.showcase()}>
            <a>{lang.page.showcase.headTitle}</a>
          </ActiveLiLink>
          <ActiveLiLink href={urls.announcements}>
            <a>{lang.page.announcements.title}</a>
          </ActiveLiLink>
          <li>
            <a href="/" onClick={this.logoutHandler()}>
              {lang.logOut}
            </a>
          </li>
        </ul>
      </>
    )
  }
}
