import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  connectToSocketEvent,
  disconnectFromSocketEvent,
} from 'common/services/socket-communication/actions'

// Usage:
// @connectSocketEvents([eventMap['mood.plot']])
// class ExampleReactComponent extends React.Component
export default function ConnectSocketEventsHoc(socketEventMappings) {
  return (WrappedComponent) => {
    class ConnectSocketEventsHOC extends React.Component {
      static propTypes = {
        connectToSocketEvent: PropTypes.func,
        disconnectFromSocketEvent: PropTypes.func,
      }

      static getInitialProps(params) {
        return WrappedComponent.getInitialProps && WrappedComponent.getInitialProps(params)
      }

      componentDidMount() {
        _.map(socketEventMappings, (eventMapping) => {
          this.props.connectToSocketEvent(eventMapping)
        })
      }

      componentWillUnmount() {
        _.map(socketEventMappings, (eventMapping) => {
          this.props.disconnectFromSocketEvent(eventMapping)
        })
      }

      render() {
        return (
          <WrappedComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
          />
        )
      }
    }

    return connect(null, (dispatch) => ({
      connectToSocketEvent: (payload) => dispatch(connectToSocketEvent(payload)),
      disconnectFromSocketEvent: (payload) => dispatch(disconnectFromSocketEvent(payload)),
    }))(ConnectSocketEventsHOC)
  }
}
