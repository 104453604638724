import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'

import lang from 'lang'
import { replace } from 'common/utils'
import Link from 'next/link'

import { urls } from 'common/routes'
import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class ResourceCreatedNotification extends React.Component {
  static propTypes = {
    resource: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }

  render() {
    const { name, slug } = this.props.resource

    return (
      <Link href={urls.resourceDetails(slug)}>
        <a>
          <div>{Parser(replace(lang.notifications.events.resource.insert, [name]))}</div>
        </a>
      </Link>
    )
  }
}
