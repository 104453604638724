import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { changeModal } from 'services/actions'
import IframeModal from 'common/components/modals/iframe'

@connect(
  () => ({}),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
  }),
)
export default class Footer extends React.Component {
  static propTypes = {
    buildId: PropTypes.string,
    withFeedback: PropTypes.bool,
    changeModal: PropTypes.func,
    hideForPrint: PropTypes.bool,
  }

  static defaultProps = {
    hideForPrint: false,
  }

  handleContactButton() {
    return () => {
      this.props.changeModal({
        isOpen: true,
        aria: {
          labelledby: 'contact',
        },
        className: 'contact',
        content: <IframeModal src="//help.ruler.online/gfembed/?f=1" title={lang.footer.contact} />,
      })
    }
  }

  render() {
    const footerText = `© ${new Date().getFullYear()} Yale University. ${
      lang.footer.allRightsReserved
    }`
    let printFooter = null
    if (!this.props.hideForPrint) {
      printFooter = (
        <footer className="footer-for-print show-for-print small-12 cell">{footerText}</footer>
      )
    }
    return (
      <div>
        <style jsx>
          {`
            .footer.footer-with-feedback {
              padding-bottom: 3rem;
            }
          `}
        </style>
        <footer
          className={`footer ${this.props.withFeedback ? 'footer-with-feedback' : ''}`}
          role="contentinfo">
          <div className="grid-container">
            <small>
              <span>build: {this.props.buildId}</span>
            </small>
            <div className="inner-footer grid-x grid-padding-x">
              <div className="small-12 medium-12 large-6 cell">
                <p className="source-org copyright">{footerText}</p>
              </div>
              <div className="small-12 medium-12 large-6 cell">
                <nav className="footer-nav">
                  <ul className="menu simple align-right">
                    <li>
                      <a href="/terms" target="_blank" rel="noopener noreferrer">
                        {lang.tos}
                      </a>
                    </li>
                    <li>
                      <a href="/privacy" target="_blank" rel="noopener noreferrer">
                        {lang.privacy}
                      </a>
                    </li>
                    <li>
                      <a href="https://help.ruler.online" rel="noopener noreferrer" target="_blank">
                        {lang.help}
                      </a>
                    </li>
                    <li>
                      <a
                        role="button"
                        onClick={this.handleContactButton()}
                        onKeyDown={this.handleContactButton()}
                        tabIndex="0">
                        {lang.footer.contact}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </footer>
        {printFooter}
      </div>
    )
  }
}
