import _ from 'lodash'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'next/link'

import lang from 'lang'
import { closeNavDropdown, openNavDropdown } from 'services/nav-dropdown/actions'
import { getActivityStreamService } from 'common/services/notifications'
import { markStreamAsRead } from 'common/services/notifications/actions'
import connectSocketEvents from 'components/connect-socket-events-hoc'
import NotificationsMap from 'components/notifications'
import { event } from 'common/services/socket-communication/event-map'
import { urls } from 'common/routes'

const NOTIFICATIONS_MENU_NAME = 'Notifications panel'

const BellIcon = () => (
  <svg version="1.1" viewBox="0 0 512 512" height="22" width="22">
    <path
      d="M467.812,431.851l-36.629-61.056c-16.917-28.181-25.856-60.459-25.856-93.312V224c0-67.52-45.056-124.629-106.667-143.04
			V42.667C298.66,19.136,279.524,0,255.993,0s-42.667,19.136-42.667,42.667V80.96C151.716,99.371,106.66,156.48,106.66,224v53.483
			c0,32.853-8.939,65.109-25.835,93.291l-36.629,61.056c-1.984,3.307-2.027,7.403-0.128,10.752c1.899,3.349,5.419,5.419,9.259,5.419
			H458.66c3.84,0,7.381-2.069,9.28-5.397C469.839,439.275,469.775,435.136,467.812,431.851z"
    />
    <path d="M188.815,469.333C200.847,494.464,226.319,512,255.993,512s55.147-17.536,67.179-42.667H188.815z" />
  </svg>
)

const AlertCounter = ({ count }) => {
  if (!count) {
    return null
  }
  if (count >= 10) {
    return <span className="alert-counter small" />
  }
  return <span className="alert-counter">{count}</span>
}

AlertCounter.propTypes = {
  count: PropTypes.number,
}

const Footer = () => {
  return (
    <div className="notification-footer">
      <Link href={urls.notifications}>
        <a>{lang.notifications.seeAll}</a>
      </Link>
    </div>
  )
}

@connectSocketEvents([
  event('school', 'insert'),
  event('person', 'insert_self_signup'),
  event('district', 'insert'),
  event('staff_charter', 'insert'),
  event('staff_charter_survey', 'insert'),
  event('mood_meter_group', 'insert'),
  event('resource', 'insert'),
  event('course', 'insert'),
  event('message', 'post'),
])
@connect(
  (state) => ({
    notifications: state.notifications.list.items,
    openedDropdown: state.navDropdown.openedDropdown,
  }),
  (dispatch) => ({
    getNotifications: () => dispatch(getActivityStreamService.actions.request()),
    markAsRead: (data) => dispatch(markStreamAsRead(data)),
    openNavDropdown: (name) => dispatch(openNavDropdown(name)),
    closeNavDropdown: () => dispatch(closeNavDropdown()),
  }),
)
export default class NotificationBar extends React.Component {
  static propTypes = {
    notifications: PropTypes.array,
    markAsRead: PropTypes.func,
    getNotifications: PropTypes.func,
    openedDropdown: PropTypes.string,
    openNavDropdown: PropTypes.func,
    closeNavDropdown: PropTypes.func,
  }

  componentDidMount() {
    this.props.getNotifications()
  }

  get opened() {
    return this.props.openedDropdown === NOTIFICATIONS_MENU_NAME
  }

  get unreadNotifications() {
    return _.filter(this.notificationsList, { seenAt: null })
  }

  get notificationsList() {
    return _.orderBy(
      _.filter(this.props.notifications, (notification) => {
        return !!NotificationsMap[notification.eventType]
      }),
      ['createdAt'],
      ['desc'],
    )
  }

  notificationsMenuHandler() {
    return (e) => {
      e.stopPropagation()
      if (this.opened) {
        this.props.closeNavDropdown()
      } else {
        this.props.openNavDropdown(NOTIFICATIONS_MENU_NAME)
      }
    }
  }

  markAsReadHandler() {
    return (e) => {
      e.preventDefault()
      this.props.markAsRead({ last_seen: 'now' })
    }
  }

  seeAllHandler() {
    return (e) => {
      e.preventDefault()
      this.props.closeNavDropdown()
    }
  }

  renderHeader() {
    return (
      <div className="notification-header">
        <span>{lang.notifications.title}</span>
        <a href="#" onClick={this.markAsReadHandler()}>
          {lang.notifications.markAllAsRead}
        </a>
      </div>
    )
  }

  renderList() {
    const notifications = this.notificationsList
    if (notifications.length === 0) {
      return (
        <ul className="notification-list">
          <li className="list-item">{lang.notifications.noNotifications}</li>
        </ul>
      )
    }
    return (
      <ul className="notification-list">
        {_.map(this.notificationsList, (notification) => {
          const NotificationComponent = NotificationsMap[notification.eventType]
          return (
            <li key={notification.id}>
              <NotificationComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...notification}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  renderDropdown() {
    if (!this.opened) {
      return null
    }
    return (
      <div
        className="dropdown-pane is-open"
        role="presentation"
        onClick={(e) => e.stopPropagation()}>
        {this.renderHeader()}
        {this.renderList()}
        <Footer />
      </div>
    )
  }

  render() {
    return (
      <div
        className={cn('mega-dropdown notifications-panel', {
          opened: this.opened,
        })}>
        <button
          id="menuButtonNotifications"
          className="button clear"
          type="button"
          onClick={this.notificationsMenuHandler()}>
          <div className="inner">
            <BellIcon />
            {!this.opened && <AlertCounter count={this.unreadNotifications.length} />}
          </div>
        </button>
        {this.renderDropdown()}
      </div>
    )
  }
}
