import React from 'react'
import ReactMomentCountDown from 'react-moment-countdown'
import PropTypes from 'prop-types'
import { isAfter, isBefore } from 'date-fns'

import lang from 'lang'
import { timeDuration } from 'common/utils'

export default class GroupTimer extends React.Component {
  static propTypes = {
    duration: PropTypes.string,
    closed: PropTypes.bool,
    startTimeDate: PropTypes.instanceOf(Date),
    endTimeDate: PropTypes.instanceOf(Date),
  }

  state = {
    countdownHasEnded: false,
  }

  get isStarted() {
    return isBefore(this.props.startTimeDate, new Date())
  }

  get isOver() {
    return isAfter(new Date(), this.props.endTimeDate)
  }

  get isClosed() {
    return this.props.closed || this.state.countdownHasEnded
  }

  get isExpired() {
    return this.isClosed || this.isOver
  }

  get countdownFormat() {
    const duration = timeDuration(this.props.duration)
    return duration.asHours() <= 1 ? 'mm:ss' : 'HH:mm:ss'
  }

  renderCountdown() {
    if (!this.isStarted) {
      return lang.page.moodMeter.notStartedShort
    }

    if (this.isExpired) {
      return lang.page.moodMeter.expired
    }

    return (
      <span>
        <ReactMomentCountDown
          toDate={this.props.endTimeDate}
          targetFormatMask={this.countdownFormat}
          onCountdownEnd={() => this.setState({ countdownHasEnded: true })}
        />{' '}
        {lang.page.moodMeter.remaining}
      </span>
    )
  }

  render() {
    return (
      <p className="time">
        <i className="icon-timer-button" />
        {this.renderCountdown()}
      </p>
    )
  }
}
