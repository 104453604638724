import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cn from 'classnames'

import { isUser } from 'common/utils'
import { getDistrictDetailsService } from 'page-modules/dashboard/district-details/service'
import { schoolDetailsService } from 'page-modules/dashboard/school-details/service'
import { changeModal } from 'services/actions'
import lang from 'lang'
import IframeModal from 'common/components/modals/iframe'
import { closeNavDropdown } from 'services/nav-dropdown/actions'

import HeaderNav from './page-navigation/header-nav'
import Footer from './footer'

@connect(
  (state) => ({
    buildId: state.root.buildId,
    openedDropdown: state.navDropdown.openedDropdown,
    user: state.root.user,
  }),
  (dispatch) => ({
    getSchoolDetails: (payload) => dispatch(schoolDetailsService.actions.request(payload)),
    getDistrictDetails: (payload) => dispatch(getDistrictDetailsService.actions.request(payload)),
    changeModal: (params) => dispatch(changeModal(params)),
    closeNavDropdown: () => dispatch(closeNavDropdown()),
  }),
)
export default class CommonLayout extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    openedDropdown: PropTypes.string,
    className: PropTypes.string,
    buildId: PropTypes.string,
    user: PropTypes.object,
    getSchoolDetails: PropTypes.func,
    getDistrictDetails: PropTypes.func,
    withFeedback: PropTypes.bool,
    hideFooterForPrint: PropTypes.bool,
    changeModal: PropTypes.func,
    closeNavDropdown: PropTypes.func,
  }

  static defaultProps = {
    hideFooterForPrint: false,
  }

  componentDidMount() {
    const { user } = this.props

    if (user !== null) {
      if (isUser.districtAdmin(user) || isUser.districtImplementationTeam(this.props.user)) {
        this.props.getDistrictDetails({ urlParams: { id: user.district_id } })
      }
      if (isUser.schoolRole(user)) {
        this.props.getSchoolDetails({ urlParams: { id: user.school_id } })
      }
    }
  }

  feedbackButtonHandler() {
    return () => {
      this.props.changeModal({
        isOpen: true,
        aria: {
          labelledby: 'feedback',
        },
        className: 'feedback',
        content: <IframeModal src="//help.ruler.online/gfembed/?f=2" title={lang.feedbackBtn} />,
      })
    }
  }

  render() {
    const withFeedback = !_.isUndefined(this.props.withFeedback) && this.props.withFeedback
    return (
      <div
        className="content-wrap"
        aria-hidden="true"
        onClick={() => {
          if (this.props.openedDropdown !== null) {
            this.props.closeNavDropdown()
          }
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            this.props.closeNavDropdown()
          }
        }}>
        <a href="#content" className="show-for-sr">
          {lang.skipToContent}
        </a>
        <HeaderNav />
        {withFeedback && (
          <button
            className="feedback-link"
            type="button"
            aria-controls="feedback-form"
            aria-haspopup="true"
            onClick={this.feedbackButtonHandler()}>
            {lang.feedbackBtn}
          </button>
        )}
        <div id="content" className={cn('content', this.props.className)}>
          {this.props.children}
        </div>
        <Footer
          withFeedback={withFeedback}
          buildId={this.props.buildId}
          hideForPrint={this.props.hideFooterForPrint}
        />
      </div>
    )
  }
}
