import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { PDFDownloadLink } from '@react-pdf/renderer'

import lang from 'lang'

export default class DownloadPdfButton extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    fileName: PropTypes.string.isRequired,
  }

  state = {
    downloadLink: null,
  }

  generateDownloadLink() {
    const { fileName, children } = this.props
    return (
      <PDFDownloadLink fileName={fileName} document={children}>
        {({ loading }) => {
          return (
            <button type="button" className="hollow button pdf-charter">
              <span className="text">
                {loading ? lang.page.charter.loadingPdf : lang.page.charter.downloadPdf}
              </span>
              <span aria-hidden="true" className="icon icon-pdf" />
            </button>
          )
        }}
      </PDFDownloadLink>
    )
  }

  downloadPdfHandler() {
    return () => {
      this.setState({
        downloadLink: this.generateDownloadLink(),
      })
    }
  }

  render() {
    if (!_.isEmpty(this.state.downloadLink)) {
      return this.state.downloadLink
    }

    return (
      <button
        type="button"
        className="hollow button pdf-charter"
        onClick={this.downloadPdfHandler()}>
        <span className="text">{lang.page.charter.generatePdf}</span>
        <span aria-hidden="true" className="icon icon-pdf" />
      </button>
    )
  }
}
