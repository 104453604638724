import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import lang from 'lang'
import { changeModal } from 'services/actions'

import { staffCharterRevisionService } from '../service'

import StaffCharterDetails from './index'

@connect(
  (state) => ({
    school: state.school.details.item,
    charter: state.staffCharter.details.charter.item,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    loadStaffCharter: (schoolId) =>
      dispatch(staffCharterRevisionService.actions.request({ data: { school_id: schoolId } })),
  }),
)
export default class StaffCharterModal extends React.Component {
  static propTypes = {
    school: PropTypes.object,
    changeModal: PropTypes.func,
    loadStaffCharter: PropTypes.func,
  }

  componentDidMount() {
    this.props.loadStaffCharter(this.props.school.id)
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-container">
          <StaffCharterDetails />
        </div>
        <div className="modal-footer">
          <button type="button" className="button hollow cancel" onClick={() => this.closeModal()}>
            {lang.closeButton}
          </button>
        </div>
      </div>
    )
  }
}
