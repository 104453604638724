import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'

import lang from 'lang'
import DownloadPdfButton from 'common/components/pdf/download-pdf-button'

import { staffCharterRevisionService } from '../service'
import StaffCharterDetailsContent from './content'
import EmotionWordsFilter from './emotion-words-filters'
import StaffCharterPlaceholder from './placeholder'
import StaffCharterPdf from '../pdf'

@connect(
  (state) => ({
    school: state.school.details.item,
    charter: state.staffCharter.details.charter.item,
    selectedEmotionWordsIds: state.staffCharter.details.filters.emotionWordsIds,
  }),
  (dispatch) => ({
    loadStaffCharter: (schoolId) =>
      dispatch(staffCharterRevisionService.actions.request({ data: { school_id: schoolId } })),
  }),
)
export default class StaffCharterDetails extends React.Component {
  static propTypes = {
    school: PropTypes.object,
    charter: PropTypes.object,
    selectedEmotionWordsIds: PropTypes.arrayOf(PropTypes.string),
    loadStaffCharter: PropTypes.func,
    hideWhenEmpty: PropTypes.bool,
    showPlaceholderWhenEmpty: PropTypes.bool,
    charterOnly: PropTypes.bool,
    hideActions: PropTypes.bool,
  }

  static defaultProps = {
    hideWhenEmpty: false,
    showPlaceholderWhenEmpty: false,
    charterOnly: false,
    hideActions: false,
  }

  state = {
    emotionWordsFilterShown: false,
  }

  componentDidMount() {
    const { charter, school } = this.props
    if (_.isEmpty(charter) || charter.school_id !== school.id) {
      this.props.loadStaffCharter(this.props.school.id)
    }
  }

  get charterEmpty() {
    return (
      _.isEmpty(this.props.charter) ||
      _.isEmpty(this.props.charter.id) ||
      _.isEmpty(this.props.charter.emotion_words)
    )
  }

  get charterTooLongForPrint() {
    const {
      selectedEmotionWordsIds,
      charter: { emotion_words: emotionWords },
    } = this.props
    const emotionWordsLimit = 6

    return (
      !_.isEmpty(emotionWords) &&
      emotionWords.length > emotionWordsLimit &&
      // if there are no selected emotion words the whole charter is shown. So the warning needs to be shown.
      (selectedEmotionWordsIds.length > emotionWordsLimit || selectedEmotionWordsIds.length === 0)
    )
  }

  toggleFiltersVisibilityHandler() {
    return () => {
      this.setState((prevState) => ({
        emotionWordsFilterShown: !prevState.emotionWordsFilterShown,
      }))
    }
  }

  renderWarning() {
    if (this.charterTooLongForPrint) {
      return (
        <div className="render-warning margin-bottom-1">
          {lang.page.charter.staffCharter.tooLongForPrint1}
          <br />
          {lang.page.charter.staffCharter.tooLongForPrint2}
        </div>
      )
    }
  }

  renderDownloadLink() {
    const { charter, school, selectedEmotionWordsIds } = this.props
    const fileName = `${school.name}'s Staff Charter.pdf`
    return (
      <DownloadPdfButton
        key={`selectedWords-${_.join(selectedEmotionWordsIds)}`}
        id={charter.id}
        fileName={fileName}>
        <StaffCharterPdf
          school={school}
          charter={charter}
          selectedEmotionWordsIds={selectedEmotionWordsIds}
        />
      </DownloadPdfButton>
    )
  }

  renderToolbar() {
    const { charter } = this.props

    return (
      <div>
        <div className="charter-toolbar">
          {this.renderDownloadLink()}
          <button
            type="button"
            className={cn('toggle-button clear button', {
              opened: this.state.emotionWordsFilterShown,
            })}
            onClick={this.toggleFiltersVisibilityHandler()}>
            <span className="text">{lang.page.charter.staffCharter.emotionWordsFilters}</span>
          </button>
        </div>
        {this.renderWarning()}
        <div
          className={cn('emotion-words-filter-container margin-bottom-1', {
            opened: this.state.emotionWordsFilterShown,
          })}>
          <EmotionWordsFilter emotionWords={charter.emotion_words} />
        </div>
      </div>
    )
  }

  render() {
    const { hideWhenEmpty, showPlaceholderWhenEmpty, charterOnly, hideActions } = this.props

    if (hideWhenEmpty && this.charterEmpty) {
      return null
    }
    if (showPlaceholderWhenEmpty && this.charterEmpty) {
      return <StaffCharterPlaceholder />
    }

    if (charterOnly) {
      return (
        <div className="charter-container">
          <div className="charter-template">
            <StaffCharterDetailsContent hideActions={hideActions} />
          </div>
        </div>
      )
    }

    return (
      <div className="charter-container">
        {this.renderToolbar()}
        <div className="charter-template">
          <StaffCharterDetailsContent hideActions={hideActions} />
        </div>
      </div>
    )
  }
}
