import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'
import StickyBox from 'react-sticky-box'

import ScrollHOC from 'common/components/scroll-hoc'
import { isAuthenticated } from 'services/selectors'
import UserImage from 'common/components/user-image'
import { closeNavDropdown, openNavDropdown } from 'services/nav-dropdown/actions'

import MainNav from './main'
import UserNav from './user'
import NotificationBar from './notification-bar'
import InfoBanner from './info-banner'

const USER_MENU_NAME = 'User name'

@connect(
  (state) => ({
    user: state.root.user,
    isAuthenticated: isAuthenticated(state),
    openedDropdown: state.navDropdown.openedDropdown,
    notifications: state.notifications.list.items,
  }),
  (dispatch) => ({
    openNavDropdown: (name) => dispatch(openNavDropdown(name)),
    closeNavDropdown: () => dispatch(closeNavDropdown()),
  }),
)
export default class HeaderNav extends React.Component {
  static propTypes = {
    openedDropdown: PropTypes.string,
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    openNavDropdown: PropTypes.func,
    closeNavDropdown: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.scrollForHeaderHandler = this.scrollForHeaderHandler.bind(this)
    this.state = {
      headerClass: 'is-anchored',
    }
  }

  get opened() {
    return this.props.openedDropdown === USER_MENU_NAME
  }

  scrollForHeaderHandler() {
    if (window.scrollY > 0) {
      this.setState({
        headerClass: 'is-stuck',
      })
    } else {
      this.setState({
        headerClass: 'is-anchored',
      })
    }
  }

  userMenuHandler() {
    return (e) => {
      e.stopPropagation()
      if (this.opened) {
        this.props.closeNavDropdown()
      } else {
        this.props.openNavDropdown(USER_MENU_NAME)
      }
    }
  }

  renderDropdown() {
    if (!this.opened) {
      return null
    }
    return (
      <div className="dropdown-pane small is-open">
        <UserNav />
      </div>
    )
  }

  renderUserMenu() {
    return (
      <div className={cn('mega-dropdown user-panel', { opened: this.opened })}>
        <button
          id="menuButton"
          className="button clear"
          type="button"
          onClick={this.userMenuHandler()}>
          <UserImage
            name={this.props.user.name}
            photoUrl={this.props.user.photo_url}
            size="40"
            fontSize="20"
          />
        </button>
        {this.renderDropdown()}
      </div>
    )
  }

  renderForAuthenticatedUser() {
    if (!this.props.isAuthenticated) {
      return null
    }

    return (
      <>
        <NotificationBar />
        {this.renderUserMenu()}
      </>
    )
  }

  render() {
    return (
      <>
        <InfoBanner />
        <StickyBox style={{ zIndex: 3 }}>
          <div id="header-wrapper">
            <ScrollHOC onWindowScroll={this.scrollForHeaderHandler}>
              <header className={`header ${this.state.headerClass}`} role="banner">
                <div className="top-bar" id="top-bar-menu">
                  <div className="top-bar-left">
                    <a className="logo" href={this.props.isAuthenticated ? '/dashboard' : '/login'}>
                      <img
                        src="https://ruleronlineimgs.s3.amazonaws.com/logos/ruler-logo.svg"
                        alt="Ruler logo"
                      />
                    </a>
                  </div>
                  <div className="top-bar-center hide-for-small-only">
                    {this.props.isAuthenticated && <MainNav />}
                  </div>
                  <div className="top-bar-right">{this.renderForAuthenticatedUser()}</div>
                </div>
              </header>
            </ScrollHOC>
          </div>
        </StickyBox>
      </>
    )
  }
}
