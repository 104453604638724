import React from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'

import lang from 'lang'
import { replace } from 'common/utils'
import Link from 'next/link'

import { urls } from 'common/routes'

import NotificationHoc from '../create-notification-hoc'

@NotificationHoc
export default class SchoolCreatedNotification extends React.Component {
  static propTypes = {
    school: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }

  render() {
    const {
      school: { name, slug },
    } = this.props

    return (
      <Link href={urls.schoolDetails(slug)}>
        <a>
          <div>{Parser(replace(lang.notifications.events.school.insert, [name]))}</div>
        </a>
      </Link>
    )
  }
}
